import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { Table, Typography } from 'antd';
import { useSelector } from "react-redux";

import 'antd/dist/antd.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';

import { css } from '@emotion/react';
import './TablePanel.css';
import ScaleLoader from 'react-spinners/ScaleLoader';
import imgData from '../../logo.png';
import TimeFilter from '../../dashboard/filters/TimeFilter';
import TimeFilterForUsageReport from '../../dashboard/filters/TimeFilterForUsageReport';
import moment from "moment-timezone";

const { Text } = Typography;

const override = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 80%;
  left: 50%;
`;

const getFilterInfo = (filter_name, data) =>{
  if(data)
    return Array.from(new Set(data.map((d)=>d[filter_name]))).map((uniqueVal)=>({'text':uniqueVal,'value':uniqueVal}))
}

const getPDFJsonData = (rawData) =>{
  return rawData.map((each) => {
    each.volume =
      each.volume < 0
        ? `(${each.volume.toFixed(1) * -1})`
        : each.volume.toFixed(1);
    each.per_day_usage =
      each.per_day_usage < 0
        ? `(${each.per_day_usage.toFixed(1) * -1})`
        : each.per_day_usage.toFixed(1);
    return each;
  });
}

const TablePanel = (props) => {
  const [filters, setFilters] = useState(null);
  const [usageData, setUsageData] = useState(props.data)
  const selectedTimeRange = useSelector(
    state => state.timeRangeDataForUsageReport.selectedTimeRange,
  );

  const applyFilters = () => {
    if(filters !== null){
      let fillsDataCopy=JSON.parse(JSON.stringify(props.data))
      Object.keys(filters).forEach((key)=>{
        if(filters[key]===null)
        {
          delete filters[key]
        }
      })

      setUsageData(
        fillsDataCopy.filter((d)=>{
        let flag=true
        for (const [key, value] of Object.entries(filters)) {
          if(!value.includes(d[key]))
          {
            flag=false
            break
          }
        }
        return flag
        })
      )
    }
    else{
      setUsageData(props.data)
    }
  }
  useEffect(() => {}, [props.isLoading]);
  useEffect(() => {
    applyFilters()
  }, [props.data, filters]);

  const handleChange = (pagination, filts) => {
    setFilters(filts);
  }

  const columns = [
    {
      title: 'Location Name',
      dataIndex: 'location_name',
      key: 'location_name',
      filters: getFilterInfo("location_name",usageData),
      onFilter: (value, record) => record.location_name.includes(value),
      sorter: (a, b) => a.location_name.localeCompare(b.location_name)
    },
    {
      title: 'Tank Number',
      dataIndex: 'tank_no',
      key: 'tank_no',
      align: 'right',
      sorter: (a, b) => a.tank_no - b.tank_no,
    },
    {
      title: 'Fluid Brand',
      dataIndex: 'fluid_brand',
      key: 'fluid_brand',
      align: 'center',
      filters: getFilterInfo("fluid_brand",usageData),
      onFilter: (value, record) => record.fluid_brand.includes(value),
      sorter: (a, b) => a.fluid_brand.localeCompare(b.fluid_brand)
    },
    {
      title: 'Fluid Segment',
      dataIndex: 'fluid_segment',
      key: 'fluid_segment',
      align: 'center',
      sorter: (a, b) => a.fluid_segment.localeCompare(b.fluid_segment)
    },
    {
      title: 'Product',
      dataIndex: 'fluid_product_type',
      key: 'fluid_product_type',
      align: 'center',
      filters: getFilterInfo("fluid_product_type",usageData),
      onFilter: (value, record) => record.fluid_product_type.includes(value),
      sorter: (a, b) => a.fluid_product_type.localeCompare(b.fluid_product_type)
    },
    {
      title: 'Tank Capacity',
      dataIndex: 'capacity_gallons',
      key: 'capacity_gallons',
      align: 'right',
      sorter: (a, b) => a.capacity_gallons - b.capacity_gallons
    },
    {
      title: 'Distributor',
      dataIndex: 'distributor_name',
      key: 'distributor_name',
      align: 'center',
      filters: getFilterInfo("distributor_name",usageData),
      onFilter: (value, record) => record.distributor_name.includes(value),
      sorter: (a, b) => a.distributor_name.localeCompare(b.distributor_name)
    },
    {
      title: 'Gallons Used',
      dataIndex: 'volume',
      key: 'volume',
      align: 'center',
      render: (text, record, index) => (text < 0 ? `(${text.toFixed(1) * -1})` : text.toFixed(1)),
      sorter: (a, b) => a.volume - b.volume,
    },
    {
      title: 'Historical Per Day Usage (Last 30 Days)',
      dataIndex: 'per_day_usage',
      key: 'per_day_usage',
      align: 'center',
      render: (text, record, index) => text < 0 ? `(${text.toFixed(1) * -1})` : text.toFixed(1),
      sorter: (a, b) => a.per_day_usage - b.per_day_usage,
    },
  ];

  //this is needed as antd doesn't trigger onChange callback when bound to external state
  //https://github.com/ant-design/ant-design/issues/19415
  let data = usageData && usageData.map(d => ({
    ...d,
  }));

  const getTimeStamp = () => {
    const m_names = new Array(
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    );

    let today = new Date();
    const curr_date = today.getDate();
    const curr_month = today.getMonth();
    const curr_year = today.getFullYear();
    const curr_hour = today.getHours() > 12 ? today.getHours() - 12 : today.getHours();

    const curr_min = today.getMinutes();
    const curr_secs = today.getSeconds();
    const curr_ampm = today.getHours() > 12 ? 'PM' : 'AM';

    today = `${m_names[curr_month]
    } ${
      curr_date
    }, ${
      curr_year
    } ${
      curr_hour
    }:${
      curr_min
    }:${
      curr_secs
    } ${
      curr_ampm}`;
    const newdat = today;

    return newdat;
  };

  const generatePDF = () => {
    const pdfColumns = [
      {
        title: 'Location Name',
        dataKey: 'location_name',
      },
      // {
      //   title: "Manufacturer",
      //   dataKey: "manufacturer_name",
      // },
      {
        title: 'Tank Number',
        dataKey: 'tank_no',
      },
      {
        title: 'Fluid Brand',
        dataKey: 'fluid_brand',
      },
      {
        title: 'Fluid Segment',
        dataKey: 'fluid_segment',
      },
      {
        title: 'Product',
        dataKey: 'fluid_product_type',
      },
      {
        title: 'Tank Capacity',
        dataKey: 'capacity_gallons',
      },
      {
        title: 'Distributor',
        dataKey: 'distributor_name',
      },
      {
        title: 'Gallons Used',
        dataKey: 'volume',
      },
      {
        title: 'Historical Per Day Usage (Last 30 Days)',
        dataKey: 'per_day_usage',
      },
    ];

    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'in',
      format: 'letter',
    });

    // date
    const timeStamp = getTimeStamp();
    doc.setFontSize(16).text('Usage Report', 0.5, 1.0);
    doc.setFontSize(14).text(`( ${selectedTimeRange.label} )`, 1.95, 1.0);
    doc.setFontSize(8).text(timeStamp, 8.8, 1.0);
    doc.setDrawColor(0, 96, 168);

    doc.setLineWidth(0.01).line(0.5, 1.1, 10.4, 1.1);
    doc.addImage(imgData, 'PNG', 3.5, 0, 2.0, 0.75);

    doc.autoTable({
      columns:pdfColumns,
      body: getPDFJsonData(JSON.parse(JSON.stringify(usageData))),
      margin: { left: 0.5, top: 1.25 },
      theme: 'striped',
      bodyStyles: {
        margin: 40,
        fontSize: 10,
        lineWidth: 0,
        lineColor: [0, 0, 0],
      },
      columnStyles: [
        { halign: 'left' },
        // { halign: "right" },
        { halign: 'right' },
        { halign: 'left' },
        { halign: 'right' },
        { halign: 'right' },
        { halign: 'right' },
        { halign: 'right' },
        { halign: 'right' },
        { halign: 'right' },
      ],
    });

    doc.save(`Usage Report ${timeStamp}.pdf`);
  };

  const exportToXlsx = () => {
    const timeStamp = getTimeStamp();
    const fileName = `Usage Report ${timeStamp}.xlsx`;

    const ws = XLSX.utils.json_to_sheet(getPDFJsonData(JSON.parse(JSON.stringify(usageData))));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Usage Report');

    XLSX.writeFile(wb, fileName);
  };

  return (
    <>
      <Table
        columns={columns}
        className="ant-table-filter-column"
        dataSource={data}
        onChange={handleChange}
        summary={() => {
          let totalVolume = data && data.length > 0
            ? data.map(d => d.volume).reduce((a, b) => a + b, 0)
            : 0;
          totalVolume = totalVolume < 0
            ? `(${totalVolume.toFixed(1) * -1})`
            : totalVolume.toFixed(1);
          const totalCapacity = data && data.length > 0
            ? data
              .map(d => d.capacity_gallons)
              .reduce((a, b) => a + b, 0)
            : 0;
          let totalPerDayUsage = data && data.length > 0
          ? data
            .map(d => d.per_day_usage)
            .reduce((a, b) => a + b, 0)
          : 0;
          totalPerDayUsage = totalPerDayUsage < 0
            ? `(${totalPerDayUsage.toFixed(1) * -1})`
            : totalPerDayUsage.toFixed(1);
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} />
              <Table.Summary.Cell index={1} />
              <Table.Summary.Cell index={2} />
              <Table.Summary.Cell index={3} />
              <Table.Summary.Cell index={4}>
                <Text strong={true}>Total</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                <Text strong={true}>{totalCapacity}</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell />
              <Table.Summary.Cell>
                <Text strong={true}>{totalVolume}</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell align="center">
                <Text strong={true}>{totalPerDayUsage}</Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
        loading={{
          indicator: (
            <ScaleLoader
              color="#0060A8"
              loading={props.isLoading}
              css={override}
              size={50}
            />
          ),
          spinning: props.isLoading,
        }}
        title={() => (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <h1>Usage Report</h1>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <p
                  style={{
                    marginBottom: '0',
                    marginRight: '1.5rem',
                    fontStyle: 'italic',
                  }}
                >
                  Download As :
                </p>

                <FontAwesomeIcon
                  onClick={generatePDF}
                  icon={faFilePdf}
                  style={{
                    color: '#0060a8',
                    fontSize: '2rem',
                    cursor: 'pointer',

                    marginRight: '10px',
                  }}
                  title="Download As PDF"
                />
                <FontAwesomeIcon
                  onClick={exportToXlsx}
                  icon={faFileExcel}
                  style={{
                    color: '#0060a8',
                    fontSize: '2rem',
                    cursor: 'pointer',

                    marginRight: '10px',
                  }}
                  title="Download As XSLX"
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <h1
                style={{ fontSize: 23, whiteSpace: 'nowrap', marginRight: 20 }}
              >
                Select Date Range
              </h1>

              <TimeFilterForUsageReport />
            </div>
          </>
        )}
      />
    </>
  );
};

const styles = () => ({
  root: { width: '100%', overflowX: 'auto' },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  head: { fontWeight: 900 },
});

export default withStyles(styles)(TablePanel);

const inventoryTablesStyle = {
  justifyContent: 'space-between',
};
