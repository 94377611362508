import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core";
import { Table, Typography } from "antd";
import {useSelector } from "react-redux";

import "antd/dist/antd.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faFilePdf } from "@fortawesome/free-solid-svg-icons";

import { css } from "@emotion/react";
import "./TablePanel.css";
import ScaleLoader from "react-spinners/ScaleLoader";
import imgData from "../../logo.png";
import TimeFilter from "../../dashboard/filters/TimeFilter";
import moment from "moment-timezone";

const { Text } = Typography;

const override = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 80%;
  left: 50%;
`;

const getFilterInfo = (filter_name, data) =>{
  if(data)
    return Array.from(new Set(data.map((d)=>d[filter_name]))).map((uniqueVal)=>({'text':uniqueVal,'value':uniqueVal}))
}

const getPDFJsonData = (rawData) =>{
  return rawData.map((each) => {
    each.start = moment(parseInt(each.start)).format("MM-DD-YY");
    each.volume =
      each.volume < 0
        ? `(${each.volume.toFixed(1) * -1})`
        : each.volume.toFixed(1);
    each.typical_day_usage =
      each.typical_day_usage < 0
        ? `(${each.typical_day_usage.toFixed(1) * -1})`
        : each.typical_day_usage.toFixed(1);
    return each;
  });
}
const TablePanel = (props) => {
  const [filters, setFilters] = useState(null);
  const [fillsData, setFillsData] = useState(props.data)
  const selectedTimeRange = useSelector(state => state.timeRangeData.selectedTimeRange);

  const applyFilters = () => {
    if(filters !== null){
      let fillsDataCopy=JSON.parse(JSON.stringify(props.data))
      Object.keys(filters).forEach((key)=>{
        if(filters[key]===null)
        {
          delete filters[key]
        }
      })

      setFillsData(
        fillsDataCopy.filter((d)=>{
        let flag=true
        for (const [key, value] of Object.entries(filters)) {
          if(!value.includes(d[key]))
          {
            flag=false
            break
          }
        }
        return flag
        })
      )
    }
    else{
      setFillsData(props.data)
    }
  }

  useEffect(() => {}, [props.isLoading]);
  useEffect(() => {
    applyFilters()
  }, [props.data, filters]);

  

  const handleChange = (pagination, filts) => {
    setFilters(filts);
  };

  const columns = [
    {
      title: "Location Name",
      dataIndex: "location_name",
      key: "location_name",
      filters: getFilterInfo("location_name",fillsData),
      onFilter: (value, record) => record.location_name.includes(value),
      sorter: (a, b) => a.location_name.localeCompare(b.location_name)
    },
    {
      title: "Tank Number",
      dataIndex: "tank_no",
      key: "tank_no",
      align: "right",
      sorter: (a, b) => a.tank_no - b.tank_no,
    },
    {
      title: "Fluid Brand",
      dataIndex: "fluid_brand",
      key: "fluid_brand",
      align: "center",
      filters: getFilterInfo("fluid_brand",fillsData),
      onFilter: (value, record) => record.fluid_brand.includes(value),
      sorter: (a, b) => a.fluid_brand.localeCompare(b.fluid_brand)
    },
    {
      title: "Fluid Segment",
      dataIndex: "fluid_segment",
      key: "fluid_segment",
      align: "center",
      sorter: (a, b) => a.fluid_segment.localeCompare(b.fluid_segment)
    },
    {
      title: "Product",
      dataIndex: "fluid_product_type",
      key: "fluid_product_type",
      align: "center",
      filters: getFilterInfo("fluid_product_type",fillsData),
      onFilter: (value, record) => record.fluid_product_type.includes(value),
      sorter: (a, b) => a.fluid_product_type.localeCompare(b.fluid_product_type)
    },
    {
      title: "Tank Capacity",
      dataIndex: "capacity_gallons",
      key: "capacity_gallons",
      align: "right",
      sorter: (a, b) => a.capacity_gallons - b.capacity_gallons
    },
    {
      title: "Distributor",
      dataIndex: "distributor_name",
      key: "distributor_name",
      align: "center",
      filters: getFilterInfo("distributor_name",fillsData),
      onFilter: (value, record) => record.distributor_name.includes(value),
      sorter: (a, b) => a.distributor_name.localeCompare(b.distributor_name)
    },
    {
      title: "Date of Fill / Empty",
      dataIndex: "start",
      key: "start",
      align: "center",
      render: (text, record, index) =>
        moment(parseInt(text)).format("MM-DD-YY"),
      sorter: (a, b) =>
        parseInt(a.start)-parseInt(b.start)
    },
    {
      title: "Fill Amount",
      dataIndex: "volume",
      key: "volume",
      align: "right",
      render: (text, record, index) =>
        text < 0 ? `(${text.toFixed(1) * -1})` : text.toFixed(1),
      sorter: (a, b) => a.volume - b.volume,
    },
    {
      title: "Typical Day Usage",
      dataIndex: "typical_day_usage",
      key: "typical_day_usage",
      align: "right",
      render: (text, record, index) =>
        text < 0 ? `(${text.toFixed(1) * -1})` : text.toFixed(1),
      sorter: (a, b) => a.typical_day_usage - b.typical_day_usage,
    },
  ];

  //this is needed as antd doesn't trigger onChange callback when bound to external state
  //https://github.com/ant-design/ant-design/issues/19415

  let data = fillsData && fillsData.map((d) => ({
    ...d,
  }));

  const getTimeStamp = () => {
    var m_names = new Array(
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    );

    var today = new Date();
    var curr_date = today.getDate();
    var curr_month = today.getMonth();
    var curr_year = today.getFullYear();
    var curr_hour =
      today.getHours() > 12 ? today.getHours() - 12 : today.getHours();

    var curr_min = today.getMinutes();
    var curr_secs = today.getSeconds();
    var curr_ampm = today.getHours() > 12 ? "PM" : "AM";

    today =
      m_names[curr_month] +
      " " +
      curr_date +
      ", " +
      curr_year +
      " " +
      curr_hour +
      ":" +
      curr_min +
      ":" +
      curr_secs +
      " " +
      curr_ampm;
    var newdat = today;

    return newdat;
  };

  const generatePDF = () => {
    const pdfColumns = [
      {
        title: "Location Name",
        dataKey: "location_name",
      },
      {
        title: "Tank Number",
        dataKey: "tank_no",
      },
      {
        title: "Fluid Brand",
        dataKey: "fluid_brand",
      },
      {
        title: "Fluid Segment",
        dataKey: "fluid_segment",
      },
      {
        title: "Product",
        dataKey: "fluid_product_type",
      },
      {
        title: "Tank Capacity",
        dataKey: "capacity_gallons",
      },
      {
        title: "Distributor",
        dataKey: "distributor_name",
      },
      {
        title: "Date of Fill / Empty",
        dataKey: "start",
      },
      {
        title: "Fill Amount",
        dataKey: "volume",
      },
      {
        title: "Typical Day Usage",
        dataKey: "typical_day_usage",
      },
    ];

    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: "letter",
    });

    const timeStamp = getTimeStamp();
    doc.setFontSize(16).text("Fills Report", 0.5, 1.0);
    doc.setFontSize(14).text(`( ${selectedTimeRange.label} )`, 1.70, 1.0);
    doc.setFontSize(8).text(timeStamp, 8.8, 1.0);
    doc.setDrawColor(0, 96, 168);

    doc.setLineWidth(0.01).line(0.5, 1.1, 10.4, 1.1);
    doc.addImage(imgData, "PNG", 3.5, 0, 2.0, 0.75);

    doc.autoTable({
      columns: pdfColumns,
      body: getPDFJsonData(JSON.parse(JSON.stringify(fillsData))),
      margin: { left: 0.5, top: 1.25 },
      theme: "striped",
      bodyStyles: {
        margin: 40,
        fontSize: 10,
        lineWidth: 0,
        lineColor: [0, 0, 0],
      },
      columnStyles: [
        { halign: "left" },
        // { halign: "right" },
        { halign: "right" },
        { halign: "left" },
        { halign: "left" },
        { halign: "left" },
        { halign: "right" },
        { halign: "left" },
        { halign: "right" },
        { halign: "right" },
        { halign: "right" },
      ],
    });

    doc.save(`Fills Report ${timeStamp}.pdf`);
  };

  const exportToXlsx = () => {
    const timeStamp = getTimeStamp();
    const fileName = `Fills Report ${timeStamp}.xlsx`;

    const ws = XLSX.utils.json_to_sheet(getPDFJsonData(JSON.parse(JSON.stringify(fillsData))));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Fills Report");

    XLSX.writeFile(wb, fileName);
  };

  return (
    <>
      <Table
        columns={columns}
        className="ant-table-filter-column"
        dataSource={data}
        onChange={handleChange}
        summary={() => {
          let totalVolume =
            data && data.length > 0
              ? data.map((data) => data.volume).reduce((a, b) => a + b, 0)
              : 0;
          totalVolume =
            totalVolume < 0
              ? `(${totalVolume.toFixed(1) * -1})`
              : totalVolume.toFixed(1);

          let totalTypicalDayUsage=
          data && data.length > 0
              ? data.map((data) => data.typical_day_usage).reduce((a, b) => a + b, 0)
              : 0;
          totalTypicalDayUsage =
            totalTypicalDayUsage  < 0
              ? `(${totalTypicalDayUsage.toFixed(1) * -1})`
              : totalTypicalDayUsage.toFixed(1);
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} />
              <Table.Summary.Cell index={1} />
              <Table.Summary.Cell index={2} />
              <Table.Summary.Cell index={3} />
              <Table.Summary.Cell index={4} />
              <Table.Summary.Cell index={5} />
              <Table.Summary.Cell index={6} />

              <Table.Summary.Cell index={7}>
                <Text strong={true}>Total</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8}>
                <Text strong={true}>{totalVolume}</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8}>
                <Text strong={true}>{totalTypicalDayUsage}</Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
        loading={{
          indicator: (
            <ScaleLoader
              color={"#0060A8"}
              loading={props.isLoading}
              css={override}
              size={50}
            />
          ),
          spinning: props.isLoading,
        }}
        title={() => (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h1>Fills Report</h1>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    marginBottom: "0",
                    marginRight: "1.5rem",
                    fontStyle: "italic",
                  }}
                >
                  Download As :
                </p>

                <FontAwesomeIcon
                  onClick={generatePDF}
                  icon={faFilePdf}
                  style={{
                    color: "#0060a8",
                    fontSize: "2rem",
                    cursor: "pointer",

                    marginRight: "10px",
                  }}
                  title="Download As PDF"
                />
                <FontAwesomeIcon
                  onClick={exportToXlsx}
                  icon={faFileExcel}
                  style={{
                    color: "#0060a8",
                    fontSize: "2rem",
                    cursor: "pointer",

                    marginRight: "10px",
                  }}
                  title="Download As XSLX"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1
                style={{ fontSize: 23, whiteSpace: "nowrap", marginRight: 20 }}
              >
                Select Date Range
              </h1>
              <TimeFilter />
            </div>
          </>
        )}
      />
    </>
  );
};

const styles = () => ({
  root: { width: "100%", overflowX: "auto" },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  head: { fontWeight: 900 },
});

export default withStyles(styles)(TablePanel);
