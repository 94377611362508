import React, { useRef, useEffect } from 'react';
import {
  Box, Grid, useMediaQuery, withStyles,
} from '@material-ui/core';
import { compose } from 'recompose';
import moment from 'moment-timezone';
import { Title, useDataProvider } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import TablePanel from './panels/TablePanelUsage';
import * as actions from '../store/actions';
import { TimezoneContext } from '../contexts';

class ComponentToPrint extends React.Component {
  render() {
    return (
      <TablePanel data={this.props.data} isLoading={this.props.isLoading} />
    );
  }
}

const TankDashboard = (props) => {
  const componentRef = useRef();
  const views = useSelector(state => state.admin.ui.viewVersion);
  const selectedLocation = useSelector(
    state => state.locationData.selectedLocation,
  );
  const selectedTank = useSelector(state => state.tankData.selectedTank);
  const tanks = useSelector(state => state.tankData.tanks);
  const selectedTimeRange = useSelector(
    state => state.timeRangeDataForUsageReport.selectedTimeRange,
  );

  const [lastData, setLastData] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const { timezone } = React.useContext(TimezoneContext);
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();

  useEffect(() => {}, [loading]);

  React.useEffect(() => {
    async function fetchLastData(tankId, fromTime, toTime) {
      const response = await dataProvider
        .getAll('tank-usage', {
          filter: { fromTime, toTime },
        })
        .then(({ data }) => data);

      response.forEach((eachTank) => {
        Object.keys(eachTank).forEach((key) => {
          if (eachTank[key] === null || eachTank[key] === '') {
            eachTank[key] = '-';
          }
        });
      });

      setLastData(response);
      setLoading(false);
    }

    if (selectedLocation && !selectedTank) {
      const filteredTanks = tanks.filter(
        tank => tank.location_id === selectedLocation.id,
      );
      dispatch({
        type: actions.SET_SELECTED_TANK,
        value: filteredTanks[0],
      });
    }

    if (selectedLocation && selectedTank && selectedTimeRange) {
      const start = moment(selectedTimeRange.start(timezone) * 1000).toISOString();
      const end = moment(selectedTimeRange.end(timezone) * 1000).toISOString();

      fetchLastData(selectedTank.id, start, end);
    }
  }, [
    selectedTank,
    selectedLocation,
    selectedTimeRange,
    tanks,
    dispatch,
    dataProvider,
    views,
    timezone,
  ]);

  return (
    <Box m={1}>
      <Title title="Tank" />

      {selectedTank !== undefined && (
        <Grid container>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={12} alignItems="stretch">
              <Grid item xs={12}>
                <Grid container spacing={12} alignItems="stretch" />
              </Grid>
              <Grid item xs={12}>
                <ComponentToPrint
                  data={lastData}
                  isLoading={loading}
                  ref={componentRef}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

const styles = theme => ({
  root: { marginTop: theme.spacing(1) },
});

export default compose(withStyles(styles, { withTheme: true }))(TankDashboard);
