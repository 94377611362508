import moment from "moment-timezone";
import * as actions from "./actions";
import ranges from "../dashboard/filters/TimeFilter/ranges";
import rangesForUsageReport from "../dashboard/filters/TimeFilterForUsageReport/ranges";

export const distributorReducer = (
  state = {
    distributors: [],
    selectedDistributor: null,
  },
  action
) => {
  switch (action.type) {
    case actions.SET_DISTRIBUTOR_DATA:
      return action.value;
    case actions.SET_SELECTED_DISTRIBUTOR:
      return { ...state, selectedDistributor: action.value };
    default:
      return state;
  }
};

export const locationReducer = (
  state = {
    locations: [],
    selectedLocation: null,
  },
  action
) => {
  switch (action.type) {
    case actions.SET_LOCATION_DATA:
      return action.value;
    case actions.SET_SELECTED_LOCATION:
      return { ...state, selectedLocation: action.value };
    default:
      return state;
  }
};

export const tankReducer = (
  state = {
    tanks: [],
    selectedTank: null,
  },
  action
) => {
  switch (action.type) {
    case actions.SET_TANK_DATA:
      return action.value;
    case actions.SET_SELECTED_TANK:
      return { ...state, selectedTank: action.value };
    default:
      return state;
  }
};

export const timeRangeReducer = (
  state = {
    ranges,
    selectedTimeRange: {
      label: "Last 7 days",
      start: () =>
        moment()
          .subtract(7, "days")
          .unix(),
      end: () => moment().unix(),
    },
  },
  action
) => {
  switch (action.type) {
    case actions.SET_TIME_RANGE_DATA:
      return action.value;
    case actions.SET_SELECTED_TIME_RANGE:
      return { ...state, selectedTimeRange: action.value };
    default:
      return state;
  }
};
export const timeRangeReducerForUsageReport = (
  state = {
    rangesForUsageReport,
    selectedTimeRange: {
      label: "Only Yesterday",
      start: tz => moment.tz(tz)
        .subtract(1, 'days')
        .startOf('day')
        .unix(),
      end: tz => moment.tz(tz)
        .subtract(1, 'days')
        .endOf('day')
        .unix(),
    },
  },
  action
) => {
  switch (action.type) {
    case actions.SET_TIME_RANGE_DATA_FOR_USAGE_REPORT:
      return action.value;
    case actions.SET_SELECTED_TIME_RANGE_FOR_USAGE_REPORT:
      return { ...state, selectedTimeRange: action.value };
    default:
      return state;
  }
};
