export const SET_DISTRIBUTOR_DATA = "SET_DISTRIBUTOR_DATA";
export const SET_SELECTED_DISTRIBUTOR = "SET_SELECTED_DISTRIBUTOR";
export const SET_LOCATION_DATA = "SET_LOCATION_DATA";
export const SET_SELECTED_LOCATION = "SET_SELECTED_LOCATION";
export const SET_TANK_DATA = "SET_TANK_DATA";
export const SET_SELECTED_TANK = "SET_SELECTED_TANK";
export const SET_TIME_RANGE_DATA = "SET_TIME_RANGE_DATA";
export const SET_SELECTED_TIME_RANGE = "SET_SELECTED_TIME_RANGE";
export const SET_TIME_RANGE_DATA_FOR_USAGE_REPORT =
  "SET_TIME_RANGE_DATA_FOR_USAGE_REPORT";
export const SET_SELECTED_TIME_RANGE_FOR_USAGE_REPORT =
  "SET_SELECTED_TIME_RANGE_FOR_USAGE_REPORT";
